<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('advising_of_students')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('advising_of_students')"
                              :isFilter="false"/>
            </template>
            <div class="row mb-4">
                <div class="w-100 d-none d-lg-block"></div>
                <div class="col-12 col-lg-3 col-xxl-3">
                    <b-input-group size="lg" class="mb-2">
                        <b-input-group-prepend is-text>
                            <i class="ri-search-line"></i>
                        </b-input-group-prepend>
                        <b-form-input
                            type="search"
                            :placeholder="$t('search')"
                            @input="searchStudent"
                        ></b-form-input>
                    </b-input-group>

                    <div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100"
                         style="height: calc(100vh - 220px)"
                    >
                        <div v-if="isStudents === true">
                            <div v-for="(item, key) in students" v-bind:key="key">
                                <b-button
                                    :variant="
                      (current_student === item.student_program_id
                        ? 'outline-primary'
                        : 'outline-secondary text-dark') +
                      ' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 flex-column align-items-start h-auto'
                    "
                                    @click="getStudent(item)"
                                >
                                    <span>{{ toUpperCase(item.name + "  " + item.surname) }}</span>
                                    <span class="text-muted">{{ item.student_number }}</span>
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="spinner-border text-light mx-auto d-flex"></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 col-xxl-9">
                    <div class="w-100" v-if="showStudent == true">
                        <h5 class="mt-1 mb-3">
                            {{ current_student_data.name + " " + current_student_data.surname }}
                        </h5>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('student_number')">
                                    {{ current_student_data.student_number }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('email')">
                                    {{ current_student_data.email }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('gsm_no')">
                                    {{ current_student_data.mobile_tel }}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('personal_email')">
                                    {{ current_student_data.personal_email }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('student_status')">
                                    {{ current_student_data.student_status_name }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('semester_status')">
                                    {{ current_student_data.semester_status_name }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('faculty_name')">
                                    {{ current_student_data.faculty_name }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('program_name')">
                                    {{ current_student_data.program_name }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('cgpa')">
                                    {{ current_student_data.cgpa }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('credits_earned')">
                                    {{ current_student_data.credits }}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('registration_type')">
                                    {{ current_student_data.registration_type }}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('legal_presence_type')">
                                    {{ current_student_data.legal_presence_type }}
                                </b-form-group>
                            </div>





                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('payment_status')">
                                    {{ this.$t('payment_status_' + current_student_data.payment_status) }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <b-button
                                    variant="outline-primary"
                                    @click="goToSlot(current_student_data.student_program_id)">
                                    {{ $t('slots').toUpper() }}
                                    <i class="ri-arrow-right-line"></i>
                                </b-button>

                                <b-button variant="outline-primary ml-3"
                                          @click="downloadTranscript(current_student_data)">
                                    <span class="d-flex">{{ $t('transcript').toUpper() }} <i
                                        class="ri-arrow-right-line"></i></span>
                                </b-button>

                                <b-button class="ml-2" :variant="showNote?
                                                  'primary'
                                                 : 'outline-primary'"
                                          @click="showNote=!showNote">
                                    <span class="d-flex">{{ $t('notes').toUpper() }} <i class="ri-arrow-right-line"></i></span>
                                </b-button>

                                <b-button class="ml-2" variant="outline-primary"
                                          @click="showModal()">
                                    <span class="d-flex">{{ $t('student_attendance_records').toUpper() }} <i
                                        class="ri-arrow-right-line"></i></span>
                                </b-button>

                            </div>
                        </div>
                        <div v-if="showNote" class="mt-3">
                            <add-note :student="current_student_data"/>
                        </div>
                    </div>
                </div>
                <CommonModal ref="studentAttendanceRecordsModal" :onHideOnlyX="true" @bHideModalHeaderClose="clear">
                    <template v-slot:CommonModalTitle>{{ $t('student_attendance_records') }}</template>
                    <template v-slot:CommonModalContent>
                        <div>
                            <ValidationObserver ref="formModalValidate">
                                <b-col sm="12">
                                 <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('academic_year')">
                                        <academic-years-selectbox
                                            v-model="form.academic_year"
                                            :validate-error="errors[0]"
                                        >

                                        </academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col sm="12">
                                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('semester')">
                                        <parameter-selectbox
                                            code="semester"
                                            v-model="form.semester"
                                            :validate-error="errors[0]"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </ValidationObserver>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <b-button variant="primary" @click="studentAttendanceRecordsReport(current_student_data.student_program_id,current_student_data.student_number)">
                                {{ $t('download') }}
                            </b-button>
                        </div>
                    </template>
                </CommonModal>

            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Services
import StudentProgramService from "@/services/StudentProgramService";
import StudentDocumentService from "@/services/StudentDocumentService";
import addNote from "@/modules/advising/pages/Notes/AddNote.vue";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import qs from "qs";
import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";

export default {
    components: {
        AcademicYearsSelectbox,
        addNote,
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t("advising_of_students"),
        };
    },
    data() {
        return {
            form: {},
            showNote: false,
            isStudents: false,
            students: [],
            royalStudents: [],
            current_student: null,
            current_student_data: {},
            showStudent: false,
        };
    },

    methods: {
        clear() {
            this.form={}
        },
        goToSlot(student_program_id) {
            window.open('/student/programs/' + student_program_id + '/slots', '_blank')
        },
        turkishStringToUpperCase(string) {
            let letters = {i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I"};
            string = string.replace(/(([iışğüçö]))/g, function (letter) {
                return letters[letter];
            });
            return string.toUpperCase();
        },

        searchStudent(key) {
            key = this.turkishStringToUpperCase(key);

            if (key.length > 2) {
                const filteredArray = this.students.filter((item) => {
                    return (
                        item.student_number.includes(key) ||
                        this.turkishStringToUpperCase(item.name).includes(key) ||
                        this.turkishStringToUpperCase(item.surname).includes(key)
                    );
                });

                this.students = filteredArray;
            } else {
                this.students = this.royalStudents;
            }
        },
        getStudent(item) {
            this.showStudent = true;
            this.showNote=false;
            this.current_student_data = item;
        },
        async getStudents() {
            let config = {
                params: {
                    limit: -1
                }
            }
            this.isStudents = false;
            StudentProgramService.getAdvisorStudents(config).then((response) => {
                if (response.data.success) {
                    this.isStudents = true;
                    this.royalStudents = response.data.data;
                    this.students = response.data.data;
                }
            });
        },
        downloadTranscript(row) {
            if (this.checkPermission('studentdocument_transcript')) {
                StudentDocumentService.downloadTranscript(row.student_program_id, this.$i18n.locale)
                    .then(response => {
                        this._downloadFile(response, row.student_number + '-' + this.$t('transcript') + '.pdf')
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        showModal(){
            this.$refs.studentAttendanceRecordsModal.$refs.commonModal.show()
        },

        async studentAttendanceRecordsReport(studentProgramId, student_number) {
            const isValid=await this.$refs.formModalValidate.validate()
            if (!isValid)return
            if(!this.checkPermission('studentdocument_dailyattendancelist')){
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
            let data={
                params:{
                    filter:{
                        ...this.form
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            StudentDocumentService.studentAttendanceRecordsReport(studentProgramId,data)
                .then(response=>{
                    const fileName=student_number + '-' + this.$t('student_attendance_records') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {
                        pdfFileData: response.data,
                        pdfFileName: fileName,
                    })
                }).catch(e => {
                  showErrors(e, null, true)
            })

        }
    },
    created() {
        this.getStudents();
    },
    mounted() {
    }
}
</script>
